import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Prometheus で Rails アプリケーションのパフォーマンスが簡単に見れますという発表をしました",
  "date": "2017-12-08T04:03:04.000Z",
  "slug": "entry/2017/12/08/130304",
  "tags": ["medley"],
  "hero": "./2017_12_08.png",
  "heroAlt": "prometheus"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。エンジニアの宍戸です。先日、社内勉強会「TechLunch」にて`}<a parentName="p" {...{
        "href": "https://prometheus.io/"
      }}>{`Prometheus`}</a>{`を使ったアプリケーションのモニタリングについて発表する機会がありましたので、その内容を少しご紹介できればと思います。`}</p>
    <h1>{`Prometheus`}</h1>
    <p><a parentName="p" {...{
        "href": "https://prometheus.io/blog/2017/11/08/announcing-prometheus-2-0/"
      }}>{`先日 2.0 がリリースされた`}</a>{`ばかりの統合監視ツールです。監視対象からメトリクスを取得し、その情報を`}<a parentName="p" {...{
        "href": "https://prometheus.io/docs/visualization/grafana/"
      }}>{`Grafana`}</a>{`と連携してグラフィカルに表示したり、`}<a parentName="p" {...{
        "href": "https://prometheus.io/docs/alerting/alertmanager/"
      }}>{`アラートマネージャ機能`}</a>{`を利用してメトリクスの状況に応じて通知を作成することなどが可能です。また、Prometheus は Pull 型のメトリクス収集形式をとっていますが、サービスディスカバリ用の設定を入れることで、対象のサーバ群の増減の度に作業すること無く、監視対象を管理することができるなど、現在のアプリケーション稼働環境にあった運用が可能なものとなっています。（Prometheus 自体に関する情報はかなり多くの方が記事を書かれているので、詳細はそちらにお任せしたいと思います 🙏）`}</p>
    <p>{`Prometheus は前職時代にもお世話になっており、そのときには Go で書かれたサーバのモニタリングに利用していました。当時から便利に利用していたこともあり、弊社のプロダクトは Rails を利用しているため、今回は Prometheus を利用して、Rails アプリケーション自体のパフォーマンスに関するメトリクスを取得してみました。`}</p>
    <h1>{`アプリケーションパフォーマンスのモニタリング`}</h1>
    <p>{`アプリケーション自身のパフォーマンスの情報を定常的にモニタリングしておく事は、潜在的なボトルネックの発見や、アクセス状況の変化、リリース単位でのパフォーマンスの変化（改善/悪化）を早期に発見することに繋がると考えています。`}</p>
    <p>{`アプリケーションのモニタリングは有料ツールを含めてかなり多くの選択肢があります。NewRelic の`}<a parentName="p" {...{
        "href": "https://newrelic.com/application-monitoring"
      }}>{`APM`}</a>{`は代表的なツールの一つで、実は現時点ではこちらを利用してモニタリングを行っています。また`}<a parentName="p" {...{
        "href": "https://stackify.com/application-performance-management-tools/"
      }}>{`その他様々なツール`}</a>{`でも同じようなインサイトを得ることができます。アプリケーションパフォーマンスのモニタリング（マネジメント）ツールについては`}<a parentName="p" {...{
        "href": "https://stackify.com/application-performance-management-tools/"
      }}>{`こちら`}</a>{`に良くまとまっていましたので、合わせて見ていただくと良いかもしれません。`}</p>
    <h1>{`メトリクスについて`}</h1>
    <p>{`Prometheus も様々な機能がありますが、監視対象となるサーバは`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Exporter`}</code>{`と表現されます。この Exporter に対して、Prometheus Server がデータを取りに来る格好です。`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/prometheus/client_ruby"
      }}>{`https://github.com/prometheus/client_ruby`}</a>{`というライブラリが公式に提供されています。こちらにある`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Prometheus::Middleware::Collector`}</code>{` と `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Prometheus::Middleware::Exporter`}</code>{` という２つの Rack middleware を利用することで設定に数行追加するだけで以下に記載した情報を Prometheus Server から利用する準備が整います。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`key`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`意味`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`http_server_requests_total`}</code></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`アプリケーションの処理したリクエスト総数`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`http_server_request_duration_seconds_bucket`}</code></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`あるエンドポイントの要求処理時間のヒストグラム`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`http_server_request_duration_seconds_sum`}</code></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`上記の合計値`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`http_server_request_duration_seconds_count`}</code></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`あるエンドポイントへのリクエストの総数`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`http_server_exceptions_total`}</code></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`アプリケーションで発生した例外の合計数`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`ここで出て来るバケットというのは特定の範囲のデータを格納する領域のようなものです。
`}<a parentName="p" {...{
        "href": "https://prometheus.io/docs/practices/histograms/"
      }}>{`ヒストグラム`}</a>{`の計算などの際に利用します。`}</p>
    <p>{`これらを用いて、`}</p>
    <ul>
      <li parentName="ul">{`直近 10 分のエンドポイント毎のレイテンシ`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`rate(http_server_request_duration_seconds_sum{path=~"/api.*"}[5m])`}</code>{` / `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`rate(http_server_request_duration_seconds_count{path=~"/api.*"}[5m])`}</code></li>
        </ul>
      </li>
      <li parentName="ul">{`API のエンドポイント毎のステータスコードが 200 以外となったレスポンスの数`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`sum(http_server_requests_total{job="rack-example", code!~"^2..$", path=~"/api.*"} offset 10m ) by (path)`}</code></li>
        </ul>
      </li>
    </ul>
    <p>{`などを`}<a parentName="p" {...{
        "href": "https://prometheus.io/docs/prometheus/latest/querying/basics/"
      }}>{`PromQL`}</a>{`という Prometheus の独自クエリ言語を用いて集計することができます。デフォルトで提供されるダッシュボードで即座に確認できるのでとても便利です。`}</p>
    <p>{`実際に発表した際のスライドはこちら。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/9d247d64bd2d4cca8783566abcc62c53" title="Prometheus でアプリーケーションモニタリング /prometheus" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "314px"
    }} data-ratio="1.78343949044586"></iframe>
    <h1>{`まとめ`}</h1>
    <p>{`今回は Prometheus を利用した Rails アプリケーションのモニタリングについてご紹介しました。
Prometheus がどうという話はあまりできませんでしたが、ここまで手軽に準備できるもんかというのは正直びっくりしました。他のツールを利用していない状態でアプリケーションの運用をしている場合には、特に Rails 等であれば簡単に監視を始められるので、導入を検討する価値はあるのではないかと思いました。`}</p>
    <p>{`現在は比較的安定してサーバを稼働させることが出来ていると思いますが、日頃からこういったメトリクスを見つつ、今後の安定運用につなげていきたいと思います。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      